<template>
	<div>
		<van-nav-bar
			title="實名認證"
			left-arrow
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div class="title">
			<van-image
				width="1.98rem"
				height="2.18rem"
				:src="isAuthentication?require('@/assets/images/authentication_y.png'):require('@/assets/images/authentication_n.png')"
			/>
			<p class="qjc-fts-24 qjc-c-dark">完成身份驗證，可核實真實身份，<br>保障合法權益</p>
		</div>
		
		<div class="info qjc-input-group">
			<van-field
				label="真實姓名"
				v-model="info.name"
				:readonly="isAuthentication"
				placeholder="請填寫您的真實姓名"
			/>
			<van-field
				label="身份證號"
				v-model="info.card"
				:readonly="isAuthentication"
				placeholder="請填寫您的身份證號"
			/>
			<van-field
				v-if="isAuthentication"
				label="證件審核"
				value="已通過"
				:readonly="isAuthentication"
			/>
			<div v-if="!isAuthentication" class="agree qjc-texta-l">
				<van-checkbox
					v-model="info.isAgree"
				>
					我已閱讀和同意
					<router-link to="/user/authentication/agreement" class="qjc-c-primary">《米因健康大數據體檢平台用戶協議》</router-link>
				</van-checkbox>
			</div>
			<van-button v-if="!isAuthentication" class="submit qjc-c-primary qjc-fts-32 qjc-borderc-primary qjc-wid-100">實名認證</van-button>
		</div>
	</div>
</template>

<script>
	import Vue from "vue"
	import { NavBar, Image, Field, Checkbox, Button } from "vant"
	Vue.use(NavBar)
	   .use(Image)
	   .use(Field)
	   .use(Checkbox)
	   .use(Button);
	
	export default{
		name: "userAuthentication",
		data (){
			return {
				isAuthentication: false,//是否已實名認證
				info: {
					name: '',
					card: '',
					isAgree: this.$route.params.isAgree?this.$route.params.isAgree:false
				}
			}
		}
	}
</script>

<style scoped>
	.info >>> .van-field__label{
		width: 1.78rem;
		text-align: left;
	}
	.info >>> .van-field__control{
		color: #A1A7B2;
	}
	.info .van-checkbox >>> .van-icon{
		position: relative;
		width: 0.24rem;
		height: 0.24rem;
	}
	.info .van-checkbox >>> .van-checkbox__icon{
		height: 100%;
	}
	.info .van-checkbox >>> .van-checkbox__label{
		margin-left: 0.11rem;
		color: #A1A7B2;
	}
	.info .van-checkbox >>> .van-icon-success:before{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		font-size: 0.2rem;
	}
</style>
<style lang="scss" scoped>
	.title{
		height: 4.44rem;
		background-color: #F2F4FC;
		padding-top: 0.64rem;
		
		p{
			width: 3.98rem;
			font-weight: 400;
			line-height: 0.42rem;
			padding-top: 0.32rem;
			margin: 0 auto;
		}
	}
	.info{
		padding: 0 0.24rem;
		
		.van-field{
			padding: 0.48rem 0 0.34rem 0;
			font-size: 0.32rem;
			font-weight: 400;
		}
		.agree{
			font-size: 0.2rem;
			margin: 0.26rem 0 0.46rem 0;
		}
		.submit{
			height: 0.78rem;
			line-height: 0.78rem;
			border-radius: 0.04rem;
		}
	}
</style>
